.background-illustration {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/aircraft.svg") no-repeat top center fixed;
  -webkit-background-size: cover, contain;
  -moz-background-size: cover, contain;
  -o-background-size: cover, contain;
  background-size: cover, contain;
  z-index: -1;

  &__dark {
    filter: opacity(0.45) blur(.15rem);
  }

  &__light {
    filter: brightness(2.5) contrast(2) opacity(0.45) blur(.15rem);
  }
}
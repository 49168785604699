.footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    min-width: 100vw;
    box-shadow: 0 2px 6px -1px rgba(0,0,0,0.2), 0 4px 7px 0 rgba(0,0,0,0.14), 0 1px 12px 0 rgba(0,0,0,0.12);
    z-index: 100;

    &__light {
        background: #fff;
    }

    &__dark {
        color: #fdfdfd;
        background: #272727;
    }
}
